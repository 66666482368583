import { type ReactNode } from "react";
import astroChart from "../../../assets/images/astro-chart.png";
import MainNavbar from "views/components/MainNavbar/MainNavbar";
import MainFooter from "views/components/MainFooter/MainFooter";
import { useAppSelector } from "../../../redux/reduxTypes";
interface appLayoutType {
  children: ReactNode;
}

const AppFramelessLayout = ({ children }: appLayoutType) => {
  const isMobile = useAppSelector((state) => state.global.isMobile);

  return (
    <div className="w-full h-svh bg-wallpaper bg-no-repeat bg-cover object-cover overflow-y-hidden fade-in-animation">
      <MainNavbar />

      <>
        <div id="stars"></div>
        <div id="stars2"></div>
      </>

      <div className="fixed top-0 left-0 flex flex-col items-center justify-center bg-transparent w-full h-full px-5 pt-[59px] pb-[41px] z-10 w888:px-2 w888:pt-[45px] w888:pb-[63px]">
        <div
          className={`relative flex flex-col items-center bg-transparent rounded-xl fade-in-message-animation max-w-[1440px] w-full h-full p-5 z-50 m-[18px] w888:p-1 w888:my-0`}
        >
          {children}
        </div>
      </div>

      {!isMobile && (
        <div className="fixed rounded-full spin-animation -bottom-36 -right-36 opacity-100 w888:-bottom-32 w888:-right-44">
          <img
            src={astroChart}
            alt="astro-chart"
            className="w888:w-[400px] w888:h-[400px]"
          />
        </div>
      )}

      <div id="modal-box"></div>
      <MainFooter isMobile={isMobile} />
    </div>
  );
};

export default AppFramelessLayout;
