import { lazy, type FC } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const PrivacyPolicy: FC | any = lazy(
  async () =>
    await lazyRetry(async () => await import("../pages/PrivacyPolicy"))
);

const TermsOfUse: FC | any = lazy(
  async () => await lazyRetry(async () => await import("../pages/TermsOfUse"))
);

const legalRouter: routeType[] = [
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    meta: {
      layout: "app_framed",
      title: "Privacy Policy",
      type: "page",
      public: true,
    },
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
    meta: {
      layout: "app_framed",
      title: "Terms Of Use",
      type: "page",
      public: true,
    },
  },
];

export default legalRouter;
