import { IoCloseOutline } from "react-icons/io5";
import ScrollWrapper from "../ScrollWrapper/ScrollWrapper";
import ClickClipboardCopy from "../ClickClipboardCopy/ClickClipboardCopy";
import { useAppSelector } from "../../../redux/reduxTypes";
import Button from "../Button/Button";
import { useNavigate } from "react-router";

const packagesTypes = [
  {
    name: "Starlight Starter",
    amountOfCredits: 40,
    price: 6.99,
    id: `${process.env.REACT_APP_STARLIGHT_STARTER}`,
  },
  {
    name: "Stellar Explorer",
    amountOfCredits: 100,
    price: 13.99,
    id: `${process.env.REACT_APP_STELLAR_EXPLORER}`,
  },
  {
    name: "Galactic Sage",
    amountOfCredits: 200,
    price: 24.99,
    id: `${process.env.REACT_APP_GALACTIC_SAGE}`,
  },
  {
    name: "Celestial Prodigy",
    amountOfCredits: 500,
    price: 49.99,
    id: `${process.env.REACT_APP_CELESTIAL_PRODIGY}`,
  },
];

const CreditsModal = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();

  const authData = useAppSelector((state) => state.authentication.authData);
  const referralCode = useAppSelector(
    (state) => state.chat.chatData.value?.referral_code
  );

  return (
    <div className="relative flex items-center justify-center w-full h-full bg-dark-blue rounded-xl">
      <div
        className="absolute top-[-15px] right-[-15px] text-white bg-[#3c414f] rounded-full hover:text-gold cursor-pointer w888:top-[-8px] w888:right-[-8px]"
        onClick={() => closeModal && closeModal()}
      >
        <IoCloseOutline className="text-3xl text-gold w888:text-2xl" />
      </div>
      <ScrollWrapper id="scrollCreditsModal" className="w-full h-full">
        <div className="flex flex-col gap-10 p-8 w-full h-full w888:px-2 w888:py-3.5">
          <h2 className="font-philosopher text-3xl text-white text-center">
            Need More Credits?
          </h2>
          <div className="flex items-center justify-center flex-wrap gap-10 mx-auto w888:gap-5">
            <div className="flex flex-col gap-1 items-center rounded-xl border border-[#ffffff42] w-[250px] h-[185px] p-2">
              <h4 className="text-gold font-normal font-philosopher text-[26px]">
                Invite Friends
              </h4>
              <p className="text-[#E0EFFF] text-sm font-light opacity-80 text-center mt-auto">
                Get one credit when someone signs up using your referral link.
              </p>
              <p className="text-[#E0EFFF] text-base font-light opacity-80 text-center mt-1.5">
                Share your code:
              </p>
              <div className="w-full px-3 mb-1">
                <ClickClipboardCopy text={`${referralCode}`} />
              </div>
            </div>
            <div className="flex flex-col gap-1 items-center rounded-xl border border-[#ffffff42] w-[250px] h-[185px] p-2 cursor-not-allowed opacity-60">
              <h4 className="text-gold font-normal font-philosopher text-[26px]">
                Watch Ads
              </h4>
              <p className="text-[#E0EFFF] text-sm font-light opacity-80 text-center mt-auto">
                Get one credit when you watch Ad. Maximum watches per day is 3
              </p>
              <div className="w-full px-3 mb-1 mt-[32px]">
                <Button type="main" text="Start Watching" className="w-full" />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 items-center justify-center flex-wrap gap-10 mx-auto w888:gap-5 w888:grid-cols-1">
            {packagesTypes.map((type, index) => {
              return (
                <div
                  key={index}
                  className={`flex justify-between items-center rounded-xl border border-[#ffffff42] w-[330px] h-[160px] p-2 w480:w-[280px] ${
                    type.name === "" ? "opacity-60 cursor-not-allowed" : ""
                  }`}
                >
                  <div className="flex flex-col justify-between h-full">
                    <h3 className="font-philosopher text-[28px] text-gold w-1/2">
                      {type.name}
                    </h3>
                    <p className="text-white font-light text-base">
                      {type.amountOfCredits} credits
                    </p>
                  </div>
                  <div className="flex flex-col justify-between items-end h-full">
                    <h3 className="font-philosopher text-2xl text-white">
                      ${type.price}
                    </h3>
                    <Button
                      type="main"
                      text="Buy Credits"
                      onClick={() =>
                        // type.name === "Starlight Starter" &&
                        navigate(`/checkout/${type.id}/${authData?.uid}`)
                      }
                      actionIco
                      customStyle={{ icon: "w-[14px] h-auto" }}
                      className={`!text-[13px] !py-1 !px-3 !font-light whitespace-nowrap ${
                        type.name === "" ? "opacity-60 cursor-not-allowed" : ""
                      }`}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default CreditsModal;
